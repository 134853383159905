import { FC, MouseEvent, ReactNode } from 'react';

export enum ButtonVariant {
    primary = 'primary',
    secondary = 'secondary',
    positive = 'positive',
    transparent = 'transparent',
    negative = 'negative',
}

export enum ButtonType {
    'button' = 'button',
    'submit' = 'submit',
    'reset' = 'reset',
}

interface Props {
    variant?: ButtonVariant;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    children: ReactNode;
    filled?: boolean;
    type?: ButtonType;
    className?: string;
}

export const Button: FC<Props> = ({
    children,
    onClick,
    variant = ButtonVariant.primary,
    filled = false,
    type = ButtonType.button,
    className,
}: Props) => {
    const buttonClassName = `${variant} ${filled ? 'filled' : ''} ${className || ''}`;

    return (
        <button type={type} className={buttonClassName} onClick={onClick}>
            {children}
        </button>
    );
};
