import { DynamicForm } from '@admin/molecules/DynamicForm';
import { BaseEntity, FormFields } from '@admin/types';

export interface Props<Entity, FormEntity> {
    id: string;
    action: string;
    handleSubmit: (data: FormEntity) => Promise<void>;
    bindDoSubmit: (callback: () => void) => void;
    entity?: Entity;
    formFields: FormFields;
}

export function Form<Entity extends BaseEntity, FormEntity>({
    id,
    action,
    handleSubmit,
    bindDoSubmit,
    entity,
    formFields,
}: Props<Entity, FormEntity>) {
    return (
        <DynamicForm
            id={id}
            items={formFields}
            data={entity}
            onSubmit={handleSubmit}
            action={action}
            bindDoSubmit={bindDoSubmit}
        />
    );
}
